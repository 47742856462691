import { useState } from 'react'
import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import { withNamespaces } from '>/i18n'

import HeroFiltersView from './HeroFiltersView'

import { GET_LOCATION } from '~/utils/queries.gql'
import { setLocationAndPeopleQuery, getPeopleOptions } from '~/utils/helpers'
import { FESTIVAL_EDITION_STATES } from '~/utils/consts'

import { useGraphModalActions } from '~/hooks/useModal'

export default withNamespaces('common')(({ t, festivalData, festivalName, ...props }) => {
  const festivalId = get(festivalData, 'id')
  const minPeopleCount = get(festivalData, 'minPeopleCount')
  const maxPeopleCount = get(festivalData, 'maxPeopleCount')
  const lastGuestExtra = ['rw', 'bw'].includes(festivalName)
  const peopleOptions = getPeopleOptions({ minPeopleCount, maxPeopleCount, lastGuestExtra })
  const defaultPeopleOptions = {
    label: `${minPeopleCount}-${maxPeopleCount} ${t('guests')}`,
    value: minPeopleCount
  }

  const [loading, setLoading] = useState(false)
  const [people, setPeople] = useState(defaultPeopleOptions)

  const locationData = useQuery(GET_LOCATION)
  const location = get(locationData, 'data.location')

  const redirectParams = {
    ...setLocationAndPeopleQuery(people.value, minPeopleCount, location),
    festivalFilter: festivalId
  }

  const isPresale = get(festivalData, 'state') === FESTIVAL_EDITION_STATES.PRESALE

  const { openModal } = useGraphModalActions()

  return (
    <HeroFiltersView
      {...props}
      festivalData={festivalData}
      festivalName={festivalName}
      loading={loading}
      setLoading={() => setLoading()}
      people={people}
      setPeopleValue={setPeople}
      peopleOptions={peopleOptions}
      redirectParams={redirectParams}
      isPresale={isPresale}
      openModal={openModal}
    />
  )
})
