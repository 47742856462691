import HeroVoucherContent from '~/components/Festival/common/HeroVoucherContent'
import FestivalInfo from '~/components/FestivalInfo'
import HeroFilters from '~/components/Festival/common/HeroFilters/HeroFiltersContainer'

const HeroContent = ({
  isVoucher,
  isPresale,
  festivalName,
  festivalData,
  regions,
  buttonLabel,
  t
}) => {

  return (
    isVoucher
      ? <HeroVoucherContent
        festivalName={festivalName}
        festivalData={festivalData}
        regions={regions}
      />
      : <div className='rw-hero-content'>
        {isPresale ?
          <>
            <div>
              <FestivalInfo
                festival={festivalData}
                className='rw-hero-content__presale'
                withPrice={false}
                withDate={false}
                hashTag={false}
                withRespect
                withCustomRespect
              />
            </div>
            <div>
              <h1
                className='rw-hero-content__presale__title'
                dangerouslySetInnerHTML={{ __html: t('hero_section.presale.title') }}
              />
            </div>
            <h2
              className='rw-hero-content__presale__subtitle'
              dangerouslySetInnerHTML={{ __html: t('hero_section.presale.subtitle') }}
            />
            <HeroFilters
              locationModifiers={['geolocation', 'hero']}
              peopleModifiers={['people']}
              buttonModifiers={['full-width', 'hero']}
              buttonLabel={buttonLabel}
              iconRight='arrow-long'
              festivalData={festivalData}
              festivalName={festivalName}
            />
          </> : <>
            <div>
              <FestivalInfo
                festival={festivalData}
                className='rw-hero-content__event'
                withPrice={false}
                withDate
                hashTag={false}
              />
            </div>
            <div>
              <span className='rw-hero-content__hash'>#</span>
              <h1
                className='rw-hero-content__title'
                dangerouslySetInnerHTML={{ __html: t('hero_section.title') }}
              />
            </div>
            <h2
              className='rw-hero-content__subtitle'
              dangerouslySetInnerHTML={{ __html: t('hero_section.subtitle') }}
            />
            <HeroFilters
              locationModifiers={['geolocation', 'hero']}
              peopleModifiers={['people']}
              buttonModifiers={['full-width', 'hero']}
              buttonLabel={buttonLabel}
              iconRight='arrow-long'
              festivalData={festivalData}
              festivalName={festivalName}
            />
          </>
        }
        {festivalData &&
          <>
            <div className='rw-hero-content__info'>
              <span className='rw-hero-content__price'>{t('hero_section.only', { price: festivalData.price })}</span>
              <span className='rw-hero-content__gratis'>{t('hero_section.gratis')}</span>
            </div>
            <span className='rw-hero-content__visa'>{t('hero_section.visa')}</span>
            <span className='rw-hero-content__fee' dangerouslySetInnerHTML={{ __html: t('hero_section.fee') }} />
          </>

        }
      </div>
  )
}

export default HeroContent
