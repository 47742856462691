import { useState } from 'react'
import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import { withNamespaces } from '>/i18n'

import HeroFiltersView from './HeroFiltersView'

import { GET_LOCATION } from '~/utils/queries.gql'
import { setLocationAndPeopleQuery, getPeopleOptions } from '~/utils/helpers'
import { INITIAL_NUMBER_OF_ATTENDEES, FESTIVAL_EDITION_STATES } from '~/utils/consts'

import { useGraphModalActions } from '~/hooks/useModal'

export default withNamespaces('common')(({ t, festivalData, ...props }) => {
  const festivalId = get(festivalData, 'id')
  const minPeopleCount = get(festivalData, 'minPeopleCount')
  const maxPeopleCount = get(festivalData, 'maxPeopleCount')
  const peopleOptions = getPeopleOptions({ minPeopleCount, maxPeopleCount })
  const defaultPeopleOptions = peopleOptions.find(option => option.value === INITIAL_NUMBER_OF_ATTENDEES)
    || peopleOptions[0]

  const [loading, setLoading] = useState(false)
  const [people, setPeople] = useState(defaultPeopleOptions)

  const locationData = useQuery(GET_LOCATION)
  const location = get(locationData, 'data.location')

  const redirectParams = {
    ...setLocationAndPeopleQuery(people.value, minPeopleCount, location),
    festivalFilter: festivalId
  }

  const isPresale = get(festivalData, 'state') === FESTIVAL_EDITION_STATES.PRESALE

  const { openModal } = useGraphModalActions()

  return (
    <HeroFiltersView
      {...props}
      festivalData={festivalData}
      loading={loading}
      setLoading={() => setLoading()}
      people={people}
      setPeopleValue={setPeople}
      peopleOptions={peopleOptions}
      redirectParams={redirectParams}
      isPresale={isPresale}
      openModal={openModal}
    />
  )
})
