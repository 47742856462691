import React from 'react'
import HeroSection from '~/components/Festival/common/HeroSection/HeroSectionGql'

export default class HeroWrapper extends React.Component {
  render () {
    return (
      <HeroSection
        slides={[
          { src: 'slider-rw-1.png' },
          { src: 'slider-rw-2.png' },
          { src: 'slider-rw-3.png' },
          { src: 'slider-rw-4.png' },
          { src: 'slider-rw-5.png' },
          { src: 'slider-rw-6.png' },
          { src: 'slider-rw-7.png' },
          { src: 'slider-rw-8.png' },
        ]}
        heroWithTag
      />
    )
  }
}
