import { hasWindow } from '~/utils/helpers'
import config from '~/config'

export const pushDataLayer = ({
  event = 'ecommerce',
  eventAction,
  category = 'ecommerce',
  data
}) => {
  config().enableAnalytics && hasWindow() && window.dataLayer && window.dataLayer.push({
    event,
    event_action: eventAction,
    [category]: data
  })
}

export const pushUIEvent = ({
  event = 'ui',
  eventAction,
  category = 'ui',
  label,
  value,
  data
}) => {
  config().enableAnalytics && hasWindow() && window.dataLayer && window.dataLayer.push({
    event,
    event_action: eventAction,
    category,
    label,
    value,
    data
  })
}
