import React from 'react'
import Swiper from 'react-id-swiper'
import bemCx from 'bem-modifiers'
import moment from 'moment'

import withLandingNamespaces from '~/hoc/withLandingNamespaces'

import HeroVoucherContent from '~/components/Festival/common/HeroVoucherContent'
import FestivalInfo from '~/components/FestivalInfo'
import HeroFilters from '~/components/HeroFilters/HeroFiltersContainer'
import PictureTag from '~/components/PictureTag'
import RwHeroContent from '~/components/Festival/rw/HeroContent'

import { FESTIVAL_EDITION_STATES } from '~/utils/consts'

const TIMEOUT_DURATION = 3000

const Content = ({
  isVoucher,
  isPresale,
  festivalName,
  festivalData,
  regions,
  heroWithPrice,
  heroWithDate,
  heroWithTag,
  buttonLabel,
  t
}) => {
  const isRW = festivalName === 'rw'

  if (isRW) {
    return <RwHeroContent
      isVoucher={isVoucher}
      isPresale={isPresale}
      festivalName={festivalName}
      festivalData={festivalData}
      regions={regions}
      buttonLabel={buttonLabel}
      t={t}
    />
  }

  return (
    isVoucher
      ? <HeroVoucherContent
        festivalName={festivalName}
        festivalData={festivalData}
        regions={regions}
      />
      : <div className='hero-section__text'>
        <div>
          <FestivalInfo
            festival={festivalData}
            className='hero-section__event'
            withPrice={!isRW && heroWithPrice}
            withDate={heroWithDate}
            hashTag={!isRW && heroWithTag && t('hero_section.hash_tag')}
          />
        </div>
        <h1
          className='hero-section__title'
          dangerouslySetInnerHTML={{ __html: t('hero_section.title') }}
        />
        <HeroFilters
          locationModifiers={['geolocation', 'hero']}
          peopleModifiers={['people']}
          buttonModifiers={['full-width', 'hero', 'big', 'with-glow']}
          buttonLabel={buttonLabel}
          festivalData={festivalData}
          festivalName={festivalName}
        />
        {!isRW && <span
          className='hero-section__description'
          dangerouslySetInnerHTML={{ __html: t('hero_section.description') }}
        />}
      </div>
  )
}

@withLandingNamespaces()
export default class HeroSection extends React.Component {

  componentDidMount () {
    this.initSwiper()
    this.changeAdSlide()
  }

  componentWillUnmount () {
    this.swiper && this.swiper.destroy()
    clearInterval(this.adInterval)
  }

  initSwiper = () => {
    this.swiper && this.swiper.init()
  }

  updateSwiper = () => {
    this.swiper && this.swiper.update()
  }

  changeAdSlide = () => {
    const { slides } = this.props
    let i = 0
    const lastSlide = slides ? slides.length - 1 : 0
    this.adInterval = setInterval(() => {
      if (i < lastSlide) {
        this.updateSwiper()
        this.swiper && this.swiper.slideNext()
        i++
      } else {
        clearInterval(this.adInterval)
      }
    }, TIMEOUT_DURATION)
  }

  get priceSentence () {
    const { festivalData, t } = this.props
    const reservationPrice = festivalData ? festivalData.price : 0
    const price = reservationPrice.toFixed(0)

    return (
      <>
        <span className='hero-section__only-text'>{t('hero_section.only')}</span>
        <span>{price}</span>
        <span>{t('hero_section.per_person')}</span>
      </>
    )
  }

  get buttonLabel () {
    const { festivalData, t } = this.props

    return festivalData === null
      ? t('hero_section.no_festival_button')
      : t('hero_section.search_button')
  }

  render () {
    const {
      festivalData,
      regions,
      festivalEditionState,
      festivalName,
      slides,
      heroWithDate = true,
      heroWithPrice = true,
      heroWithTag,
      t
    } = this.props

    // TODO: remove this quick fix after 12.11.2023
    // const isVoucher = festivalEditionState === FESTIVAL_EDITION_STATES.VOUCHER
    // in moment months are 0 indexed so month(10) is 11th month

    const today = moment()
    const startSpecialDate = moment().month(10).date(11) // 11th November
    const endSpecialDate = moment().month(10).date(13) // 13th November
    const isSpecialDate = today.isSameOrAfter(startSpecialDate) && today.isSameOrBefore(endSpecialDate)
    const isVoucher = isSpecialDate || festivalEditionState === FESTIVAL_EDITION_STATES.VOUCHER

    // ^^^^ REMOVE after 12.11.2023 ^^^^

    const isPresale = festivalEditionState === FESTIVAL_EDITION_STATES.PRESALE

    const swiperParams = {
      init: false,
      effect: 'fade',
      speed: 500,
      pagination: {
        type: 'bullets',
        el: '.hero-section__pagination',
        bulletClass: 'hero-section__bullet',
        bulletActiveClass: 'hero-section__bullet--active'
      },
      on: {
        slideChange: () => {
          this.updateSwiper()
        },
      }
    }

    return (
      <section className={bemCx('hero-section', [{ 'voucher': isVoucher }, festivalName])}>
        <div className='hero-section__content'>
          <Content
            festivalName={festivalName}
            festivalData={festivalData}
            regions={regions}
            isVoucher={isVoucher}
            isPresale={isPresale}
            heroWithPrice={heroWithPrice}
            heroWithDate={heroWithDate}
            heroWithTag={heroWithTag}
            buttonLabel={this.buttonLabel}
            t={t}
          />
        </div>
        <div className='hero-section__swiper'>
          <Swiper {...swiperParams} ref={node => { if (node) { this.swiper = node.swiper } }}>
            {slides && slides.map((slide, index) => {
              const slideSrc = slide.src || slide
              const slideHref = slide.href || null
              return (<PictureTag
                key={slideSrc}
                src={`assets/${slideSrc}`}
                href={slideHref}
                blankMediaQuery='(max-width: 767px)'
                pictureClassName='swiper-slide'
                className='hero-section__slide-img'
              />)
            })}
          </Swiper>
        </div>
      </section>
    )
  }
}
